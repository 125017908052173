//my theme colors
$its-magenta: #ff008a;
$its-light-gray: #d0d4dc;
$its-blu-logo: #002c54; //0,44,84
$its-ruby-red-dark: #a31621;
$its-ruby-red: #cf0e1e;
$its-orange: #e08e45;
$its-yellow: #FCED4F; // 252, 237, 79
$its-orange: #f08c22; // 241, 152, 55
$its-orange2: #f6cb55; //246, 202, 85
$its-green: #00e069; //0,224,105
$text-color: black;
$blu-main-gradient: #63768d;



$bg-gradient: linear-gradient(180deg, $blu-main-gradient 0%, $its-light-gray 60%);
$bg-gradient-ios: linear-gradient(180deg, $blu-main-gradient 0%, $its-light-gray 30%, $its-light-gray 60%, $blu-main-gradient 100% );
$footer-bg-gradient: linear-gradient(180deg, $its-light-gray, $blu-main-gradient);
$factory-bg-gradient: linear-gradient(180deg, rgba(0,44,84,1) 0%, rgba(208,212,220,1) 40%);

//bootstrap variables overrides
$card-border-radius: 12px;
$primary: $its-blu-logo;

@import "../node_modules/bootstrap/scss/bootstrap.scss";