@import "main";

nav {
    &.navbar {
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s linear;
    }
}

#landing {
    height: 100vh;
    background: $bg-gradient;
    background-attachment: fixed;

    &.mobile-ios {
        background-attachment: scroll;
        background: $bg-gradient-ios !important;
    }

    #intersail-logo {
        @media (min-width: 991px) {
            margin-bottom: 0;
            width: 50%;
        }
        min-width: 350px;
        width: 45vw;
        margin-bottom: 20vh;
    }

    #scroll-down-arrow-container {
        transition: all 0.2s linear;

        img {
            @media (min-width:991px) {
                height: 48px;
            }
            height: 24px;
        }
    }

    svg {
        width: 100%;
        height: auto;

        //linea separatrice
        #line{
            &.slow-draw {
                stroke-dasharray: 789.586;
                stroke-dashoffset: 0;
                animation-name: dash;
                animation-timing-function: linear;
            }

            @keyframes dash {
                from {
                    stroke-dashoffset: 789.586; //valore preso dall'svg originale
                    opacity: 1;
                }
                to {
                    stroke-dashoffset: 0;
                    opacity: 1;
                }
              }
        }
    }
}

#souls {
    #hero-message {
        background: $bg-gradient;
        background-attachment: fixed;

        &.mobile-ios {
            background-attachment: scroll;
        }

        & > p {
            position: sticky;
            top: 20%;
        }
    }

    .soul {
        min-height: 40vh;
    }
}

#achievements {
    img {
        &.customer {
            width: 64px;
            height: 64px;
        }
    }
}


#certification-logo-container {
    bottom: 28px;
    transition: all 0.4s linear;

    @media (min-width:991px) {
        bottom: 0;
    }

    img {
        height: 20vh;
        max-height: 120px;
    }
}

#certification-logo-footer-container img {
    height: 100px;
}