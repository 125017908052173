// animate.css additional custom classes
$animate_prefix: "animate__";
$increment: 300;
$count: 6;

@for $i from 0 through $count {
  // Calculate the delay for the current iteration
  $value: $increment * $i;

  .#{$animate_prefix}delay-#{$value}ms {
    -webkit-animation-delay: #{$value}ms;
    animation-delay: #{$value}ms;
  }

  .#{$animate_prefix}speed-#{$value}ms {
    -webkit-animation-duration: #{$value}ms;
    animation-duration: #{$value}ms;
  }
}