@mixin viewportFixedTopFullWidth {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

@mixin textHighlight{
    color: $its-ruby-red;
        // text-shadow: 
        // -1px 1px 0 $its-orange2,
        // 1px 1px 0 $its-orange2,
        // 1px -1px 0 $its-orange2,
        // -1px -1px 0 $its-orange2,;
}
