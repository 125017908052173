// @font-face {
//     font-family: optima;
//     src: url("../../assets/fonts/Optima-Regular-01.ttf");
// }

// @font-face {
//     font-family: sfProLight;
//     src: url("../../assets/fonts/SF-Pro-Display-Light.otf");
// }

// @font-face {
//     font-family: sfProThin;
//     src: url("../../assets/fonts/SF-Pro-Display-Thin.otf");
// }

// @font-face {
//     font-family: sfProBold;
//     src: url("../../assets/fonts/SF-Pro-Display-Bold.otf");
// }

// @font-face {
//     font-family: sfProRegular;
//     src: url("../../assets/fonts/SF-Pro-Display-Regular.otf");
// }
// @font-face {
//     font-family: sfProTextThin;
//     src: url("../../assets/fonts/SF-Pro-Text-Thin.otf");
// }
// @font-face {
//     font-family: sfProTextLight; //ha letter spacing più grande. Ottimizzato per la lettura
//     src: url("../../assets/fonts/SF-Pro-Text-Light.otf");
// }
// @font-face {
//     font-family: sfProSemibold;
//     src: url("../../assets/fonts/SF-Pro-Display-Semibold.otf");
// }
// @font-face {
//     font-family: avenir;
//     src: url("../../assets/fonts/Avenir\ Next.ttc");
// }

.title{
    // font-family: sfProSemibold;
    font-family: 'Inter', sans-serif;
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: -2px;
}

.subtitle{
    // font-family: sfProRegular;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 2rem;    
    letter-spacing: -2px;
}

.description{
    // font-family: sfProTextLight;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 1.4rem;
}

.small-note{
    @extend .description;
    font-size: 0.8rem;
}

.cta{
    font-size: 2rem;
    font-weight: 300;
}

.hero{

    @media (min-width:991px) {
        font-size: 8rem;    
    }
    font-size: 3rem;
    font-family: 'Bebas Neue', cursive;
    span.strong{
        @include textHighlight;
        @media (min-width:991px) {
            font-size: 9.5rem;    
        }
        font-size: 4rem;
    }
}

span.highlight{
    color: $its-yellow;
}

.menu-voice{
    font-size: 1.3rem;
    font-weight: 300;
}

.achievement{
    .number{
        color: $its-ruby-red;
        font-weight: 300;
        font-size: 3.5rem;
    }

    .description{
        font-size: 1.8rem;
    }
}

